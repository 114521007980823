import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService, isNullOrUndefined } from '@equans/core';
import { Observable, catchError, throwError } from 'rxjs';

const ACCESS_DENIED_HANDLING_MODE = new HttpContextToken<'popup' | 'redirect' | undefined>(() => 'popup');
export const ACCESS_DENIED_ROUTE = 'access-denied';
export const ACCESS_DENIED_MESSAGE = 'access-denied';

export function withRedirectOnAccessDenied(httpContext = new HttpContext()): HttpContext {
  return httpContext.set(ACCESS_DENIED_HANDLING_MODE, 'redirect');
}

export function withoutAccessDeniedHandling(httpContext = new HttpContext()): HttpContext {
  return httpContext.set(ACCESS_DENIED_HANDLING_MODE, undefined);
}

export function accessDeniedInterceptor(): HttpInterceptorFn {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const handlingMode = req.context.get(ACCESS_DENIED_HANDLING_MODE);
    if (isNullOrUndefined(handlingMode)) {
      return next(req);
    }

    const router = inject(Router);
    const broadcastService = inject(BroadcastService);
    return next(req).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          switch (handlingMode) {
            case 'popup':
              broadcastService.publish({ type: ACCESS_DENIED_MESSAGE }, true);
              break;
            case 'redirect':
              void router.navigate(['/', ACCESS_DENIED_ROUTE]);
              break;
          }
        }

        return throwError(() => error);
      })
    );
  };
}
